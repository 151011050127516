<template>
  <div style="position: relative; height: 80vh;">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 0 5px 0;
      "
    >
      <span class="parrafo-small"> Elige una contraseña nueva. </span>
    </div>

    <div class="centerCenter">
        <ion-item mode="md">
          <ion-label position="floating" class="labelText"
            >Nueva contraseña</ion-label
          >

          <ion-input
            :type="passwordFieldType"
            name="password"
            v-model="vv.password.$model"
            v-bind="password"
          />
          <ion-icon
            slot="end"
            :icon="passwordEyeStyle"
            @click="switchPasswordVisibility"
            style="margin-top: 20px"
          ></ion-icon>
        </ion-item>
    </div>

    <div class="centerCenter">
      <div class="contenedor" style="width: 100%; justify-content: left;">
        <p
          class="error-msg"
          v-if="vv?.password?.$errors[0]?.$message === 'Value is required'"
          >
            Contraseña requerida
        </p>
      </div>
    </div>

    <div class="centerCenter">
      <div class="contenedor" style="width: 100%; justify-content: left;">
        <p
            class="error-msg"
            v-if="
              vv?.password?.$errors[0]?.$message ===
              'This field should be at least 8 long.'
            "
          >
            Debe tener un mínimo de 8 caracteres.
        </p>
      </div>
    </div>
      
      
      

    <div style="margin-top: 30px">

      <div class="centerCenter">
        <ion-item mode="md">
          <ion-label position="floating" class="labelText"
            >Confirmar nueva contraseña</ion-label
          >
          <ion-input
            :type="repeatPasswordFieldType"
            name="repeatPassword"
            v-model="vv.repeatPassword.$model"
          />

          <ion-icon
            slot="end"
            :icon="repeatPasswordEyeStyle"
            @click="switchRepeatPasswordVisibility"
            style="margin-top: 20px"
          ></ion-icon>
        </ion-item>
      </div>

      <div class="centerCenter">
        <div class="contenedor" style="width: 100%; justify-content: left;">
            <p
            class="error-msg"
            v-if="vv?.repeatPassword?.$errors[0]?.$message === 'Value is required'"
          >
            Contraseña requerida
          </p>
        </div>
      </div>

      <div class="centerCenter">
        <div class="contenedor" style="width: 100%; justify-content: left;">
          <p
            class="error-msg"
            v-if="vv.repeatPassword.$model !== vv.password.$model"
          >
            Las contraseñas deben ser iguales
          </p>
        </div>
      </div>


      <div class="centerCenter">
        <div class="contenedor" style="width: 100%; justify-content: left;">
          <p
            class="error-msg"
            v-if="
              vv?.repeatPassword?.$errors[0]?.$message ===
              'This field should be at least 8 long.'
            "
          >
            Debe tener un mínimo de 8 caracteres.
          </p>
        </div>
      </div>
      
      
    </div>

    <div class="centerCenter" style="position: absolute; bottom: 0; width: 100%">
      <ion-button
        class="btn buttonIniciar"
        expand="block"
        fill="solid"
        v-on:click="openModal"
        :disabled="
          vv?.password?.$errors.length > 0 ||
          vv?.repeatPassword?.$errors.length > 0 ||
          vv?.password.$dirty == false ||
          vv?.repeatPassword.$dirty == false ||
        vv.password.$model !== vv.repeatPassword.$model
        "
        >Guardar</ion-button
      >
    </div>
  </div>
</template>

<script >
import {
  IonLabel,
  IonInput,
  IonItem,
  IonButton,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import router from "../../../router/index";
import { reactive, toRef } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import AccountService from "../../../services/account.service";
import Modal from "../../../components/Modals/ModalCambiarPassword";
import ModalError from "../../../components/Modals/ModalCambiarPasswordError";


export default defineComponent({
  name: "RecuperarPasswordForm",
  components: {
    IonLabel,
    IonInput,
    IonItem,
    IonButton,
    IonIcon
  },
  data() {
    return {
      passwordFieldType: "password",
      passwordEyeStyle: eyeOffOutline,
      repeatPasswordFieldType: "password",
      repeatPasswordEyeStyle: eyeOffOutline,
    };
  },
  props: {
    myCode: String,
    email: String,
  },
  setup() {
    const fform = reactive({
      password: "",
      repeatPassword: "",
    });

    const rules = {
      password: { required, minLength: minLength(8) },
      repeatPassword: { required, minLength: minLength(8) },
    };
    const vv = useVuelidate(rules, {
      password: toRef(fform, "password"),
      repeatPassword: toRef(fform, "repeatPassword"),
    });

    const submitForm = () => {
      vv.value.$touch();
      if (vv.value.$invalid) return;
      AccountService.loginRequest({
        email: fform.emailAddress,
        password: fform.password,
      });
    };

    return {
      submitForm,
      vv,
      eyeOutline,
      eyeOffOutline,
    };
  },
  methods: {
    goToRecover() {
      router.replace("recuperarPassword");
    },
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordEyeStyle =
        this.passwordEyeStyle === eyeOffOutline ? eyeOutline : eyeOffOutline;
    },
    switchRepeatPasswordVisibility() {
      this.repeatPasswordFieldType =
        this.repeatPasswordFieldType === "password" ? "text" : "password";
      this.repeatPasswordEyeStyle =
        this.repeatPasswordEyeStyle === eyeOffOutline
          ? eyeOutline
          : eyeOffOutline;
    },
    async openModal() {

      AccountService.recoverChangePassword({
        password: this.vv.password.$model,
        token: this.myCode,
        email: this.email,
      }).then( async (data) => {
        if (data === "success") {
          const modal = await modalController.create({
          component: Modal,
          mode: "md"
        });
          return modal.present();
        }
        else{
          const modalError = await modalController.create({
            component: ModalError,
            componentProps: {
              message: data.message
            },
            mode: 'md'
          })
          return modalError.present()
        }
      });
    },
  },
});
</script>

<style scoped>
ion-item {
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid #b4b4b4;
  --background: white;
  margin-top: 10px;
  --highlight-color-focused: #d4692700;
  width: 100%;
  max-width: 700px;
}

ion-item.item-has-focus {
  border-bottom: 3px solid #d46927;
}

ion-item.item-has-focus > ion-label {
  color: #d46827 !important;
}
</style>
