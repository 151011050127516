<template>
  
  <ion-page style="background-color: rgba(255, 255, 255, 0.514);">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="login"></ion-back-button>
      </ion-buttons>
      <ion-title> Olvidaste tu contraseña</ion-title>
    </ion-toolbar>
    <ion-grid class="grid" v-if="step1">
      <ion-row>
        <ion-col class="grid__content">
          <h3 class="subtitle-1 ion-text-center">
            Escribí tu correo electrónico y te enviaremos instrucciones para
            restablecer la contraseña.
          </h3>
        </ion-col>
      </ion-row>
      <ion-row class="w-100">
        <ion-col>
          <div class="centerCenter">
            <ion-item mode="md">
              <ion-label position="floating" class="labelText">Email</ion-label>
              <ion-input
                type="email"
                name="emailAddress"
                v-model="vv.emailAddress.$model"
              />
            </ion-item>
          </div>
          <div class="centerCenter">
            <div class="contenedor" style="width: 100%; justify-content: left;">
              <p
              class="error-msg"
              v-if="
                vv?.emailAddress?.$errors[0]?.$message === 'Value is required'
                "
              >
                Email requerido
              </p>
            </div>
          </div>
          <div class="centerCenter">
            <div class="contenedor" style="width: 100%; justify-content: left;">
              <p
              class="error-msg"
              v-if="
                vv?.emailAddress?.$errors[0]?.$message ===
                'Value is not a valid email address'
                "
              >
                Dirección de email invalida
              </p>
            </div>
          </div>
          
          
        
        </ion-col>
      </ion-row>
      <ion-row class="grid__actions">
        <ion-col>
          <div class="centerCenter">
            <ion-button
            class="btn buttonIniciar"
            expand="block"
            fill="solid"
            :disabled="
              vv?.emailAddress?.$errors.length > 0 ||
              vv?.emailAddress.$dirty == false
            "
            v-on:click="enviar1"
            >Enviar</ion-button
          >
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="grid" v-if="step2">
      <ion-row>
        <ion-col class="grid__content">
          <h3 class="subtitle-1 ion-text-center">
            Ingresa el codigo de validación enviado a tu Email.
          </h3>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div style="margin-top: 50px">
            <ValidationCode
              @enable-button="enableB($event)"
              @disable-button="fourDigit = false"
              ref="validation"
            />
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
            <ion-col>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 30px 0 50px 0;
                "
              >
                <span class="parrafo-small">
                  No recibí el codigo
                  <span
                    style="color: #e33613; font-weight: bold"
                    v-on:click="reenviar"
                    >REENVIAR</span
                  >
                </span>
              </div>
            </ion-col>
          </ion-row>
      <ion-row class="grid__actions">
        <ion-col>
          <div class="centerCenter">
            <ion-button
                  class="btn buttonIniciar"
                  expand="block"
                  fill="solid"
                  v-on:click="enviar2"
                  :disabled="fourDigit === false"
                  >Enviar</ion-button
                >
          </div>
          
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="grid" v-if="step3">
      <ion-row class="w-100">
        <ion-col>
          <RecuperarPasswordForm
          :myCode="myCode"
          :email="vv.emailAddress.$model"
        />
        </ion-col>
      </ion-row>
    </ion-grid>
  
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { reactive, toRef } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import {
  IonPage,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonToolbar,
  toastController
} from "@ionic/vue";
import ValidationCode from "../../components/ValidationCode";
import RecuperarPasswordForm from "./components/RecuperarPasswordForm";
import AccountService from "../../services/account.service";
import { useStore } from "vuex";
export default defineComponent({
  name: "RecuperarPassword",
  components: {
    IonPage,
    IonCol,
    IonGrid,
    IonRow,
    IonButton,
    IonLabel,
    IonInput,
    IonItem,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonToolbar,
    ValidationCode,
    RecuperarPasswordForm,
  },
  setup() {
    const fform = reactive({
      emailAddress: "",
    });
    const rules = {
      emailAddress: { required, email },
    };
    const vv = useVuelidate(rules, {
      emailAddress: toRef(fform, "emailAddress"),
    });

    const onSubmit = () => {
      vv.value.$touch();
      if (vv.value.$invalid) return;
      console.log(vv);
    };

    return {
      onSubmit,
      vv,
    };
  },
  data() {
    return {
      step1: true,
      step2: false,
      step3: false,
      password: "",
      fourDigit: false,
      enviar1Button: false,
      myCode: "",
    };
  },
  methods: {
    enviar1() {
      console.log(this.vv.emailAddress.$model);
      AccountService.forgot({ email: this.vv.emailAddress.$model }).then((data) => {
        if (data === "success") {
          (this.step1 = false), (this.step2 = true);
        }
      });
    },
    enviar2() {
      /* ESTO ES LO QUE SE TIENE QUE EJECUTAR CUANDO ARREGLEN EL ENDPOINT
      AccountService.checkToken({
        email: this.vv.emailAddress.$model,
        token: this.myCode,
      }).then((data) => {
        if (data === "success") {
          (this.step1 = false), (this.step2 = false), (this.step3 = true);
        }
      })
      */
     (this.step1 = false), (this.step2 = false), (this.step3 = true);
    },
    enableB(code) {
      this.fourDigit = true;
      this.myCode = code;
    },
    reenviar() {
      AccountService.forgot({ email: this.vv.emailAddress.$model }).then((data) => {
        if (data === "success") {
          this.openToastReenviado();
        }
      });
      this.$refs.validation.reenviar();
    },
    async openToastReenviado() {
      const message = "Código reenviado";
      const toast = await toastController
      .create({
        cssClass: 'success',
        position: 'middle',
        header: 'Éxito',
        message: message,
        duration: 2000
      })
      return toast.present();
    }
  },
});
</script>
<style lang="scss" scoped>
.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 38px 14px 35px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }

  &__actions {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
  }
}

.label {
  display: flex;
  align-items: center;

  img {
    margin-right: 20px;
  }

  h5 {
    margin: 0;
  }
}

ion-item {
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid #b4b4b4;
  --background: white;
  margin-top: 10px;
  --highlight-color-focused: #d4692700;
  width: 100%;
  max-width: 700px;
}

ion-item.item-has-focus {
  border-bottom: 3px solid #d46927;
}

ion-item.item-has-focus > ion-label {
  color: #d46827;
}



@media (min-width: 740px) {
    ion-toolbar {
      --padding-start: 2.5vw;
    }
  }
  @media (min-width: 780px) {
    ion-toolbar {
      --padding-start: 5vw;
    }
  }
  @media (min-width: 840px) {
    ion-toolbar {
      --padding-start: 7.5vw;
    }
  }
  @media (min-width: 870px) {
    ion-toolbar {
      --padding-start: 8.5vw;
    }
  }
  @media (min-width: 900px) {
    ion-toolbar {
      --padding-start: 10vw;
    }
  }
  @media (min-width: 950px) {
    ion-toolbar {
      --padding-start: 12.5vw;
    }
  }
  @media (min-width: 1000px) {
    ion-toolbar {
      --padding-start: 13vw;
    }
  }
  @media (min-width: 1040px) {
    ion-toolbar {
      --padding-start: 15vw;
    }
  }
  @media (min-width: 1080px) {
    ion-toolbar {
      --padding-start: 17.5vw;
    }
  }
  @media (min-width: 1200px) {
    ion-toolbar {
      --padding-start: 20vw;
    }
  }
  @media (min-width: 1250px) {
    ion-toolbar {
      --padding-start: 21vw;
    }
  }
  @media (min-width: 1300px) {
    ion-toolbar {
      --padding-start: 22.5vw;
    }
  }
  @media (min-width: 1400px) {
    ion-toolbar {
      --padding-start: 25vw;
    }
  }
  @media (min-width: 1500px) {
    ion-toolbar {
      --padding-start: 26vw;
    }
  }
  @media (min-width: 1600px) {
    ion-toolbar {
      --padding-start: 27.5vw;
    }
  }
  @media (min-width: 1650px) {
    ion-toolbar {
      --padding-start: 28vw;
    }
  }
  @media (min-width: 1700px) {
    ion-toolbar {
      --padding-start: 30vw;
    }
  }
  @media (min-width: 2000px) {
    ion-toolbar {
      --padding-start: 32.5vw;
    }
  }
</style>
