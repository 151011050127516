<template>
  <div class="centerCenter">
    <div style="width: 210px">
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-input
              v-model="input1"
              ref="myInput1"
              maxlength="1"
              inputmode="numeric"
              class="verification-code"
              :class="{ 'orange-border': input1 != '' }"
              v-on:keyup="gotoInput2"
            />
          </ion-col>
          <ion-col>
            <ion-input
              v-model="input2"
              ref="myInput2"
              maxlength="1"
              inputmode="numeric"
              class="verification-code"
              :class="{ 'orange-border': input2 != '' }"
              v-on:keyup="gotoInput3"
            />
          </ion-col>
          <ion-col>
            <ion-input
              v-model="input3"
              ref="myInput3"
              maxlength="1"
              inputmode="numeric"
              class="verification-code"
              :class="{ 'orange-border': input3 != '' }"
              v-on:keyup="gotoInput4"
            />
          </ion-col>
          <ion-col>
            <ion-input
              v-model="input4"
              ref="myInput4"
              maxlength="1"
              inputmode="numeric"
              class="verification-code"
              :class="{ 'orange-border': input4 != '' }"
              v-on:keyup="lastInput"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

</template>
<script>
import { IonInput, IonCol, IonGrid, IonRow } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "validation-code",
  components: {
    IonInput,
    IonCol,
    IonGrid,
    IonRow,
  },
  data() {
    return {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      fourDigit: false,
    };
  },
  watch: {
    fourDigit() {
      if (this.fourDigit === true) {
        const my4DigitCode = this.input1 + this.input2 + this.input3 + this.input4; 
        this.$emit("enable-button", my4DigitCode);
      } else {
        this.$emit("disable-button");
      }
    },
    input1() {
      if (
        this.input1 != "" &&
        this.input2 != "" &&
        this.input3 != "" &&
        this.input4 != ""
      ) {
        this.fourDigit = true;
      } else {
        this.fourDigit = false;
      }
    },
    input2() {
      if (
        this.input1 != "" &&
        this.input2 != "" &&
        this.input3 != "" &&
        this.input4 != ""
      ) {
        this.fourDigit = true;
      } else {
        this.fourDigit = false;
      }
    },
    input3() {
      if (
        this.input1 != "" &&
        this.input2 != "" &&
        this.input3 != "" &&
        this.input4 != ""
      ) {
        this.fourDigit = true;
      } else {
        this.fourDigit = false;
      }
    },
    input4() {
      if (
        this.input1 != "" &&
        this.input2 != "" &&
        this.input3 != "" &&
        this.input4 != ""
      ) {
        this.fourDigit = true;
      } else {
        this.fourDigit = false;
      }
    },
  },
  methods: {
    gotoInput2() {
      setTimeout(() => {
        if (this.input1 === "") {
          return
        } else {
          this.$refs.myInput2.$el.setFocus();
        }
      }, 100);
    },
    gotoInput3() {
      setTimeout(() => {
        if (this.input2 === "") {
        this.$refs.myInput1.$el.setFocus();
        } else {
          this.$refs.myInput3.$el.setFocus();
        }
      }, 100);
    },
    gotoInput4() {
      setTimeout(() => {
        if (this.input3 === "") {
        this.$refs.myInput2.$el.setFocus();
        } else {
          this.$refs.myInput4.$el.setFocus();
        }
      }, 100);
    },
    lastInput() {
      setTimeout(() => {
        if (this.input4 === "") {
        this.$refs.myInput3.$el.setFocus();
        } else {
          return
        }
      }, 100);
    },
    reenviar() {
      this.input1 = "";
      this.input2 = "";
      this.input3 = "";
      this.input4 = "";
    },
  },
});
</script>


<style scoped>
ion-col {
  padding: 0 !important;
}

.verification-code {
  width: 45px;
  height: 55px;
  background-color: #fff;
  font-weight: bold;
  font-family: "Avenir" !important;
  color: rgba(0, 0, 0, 0.51);
  font-size: 24px !important;
  border: 1px solid rgba(155, 155, 155, 0.699) !important;
  justify-content: center;
  border-radius: 5px;
  margin: 0px;
  padding-right: 5px !important;
}

.has-focus {
  border: 1px solid #d46827 !important;
}

.orange-border {
  border: 1px solid #d46827 !important;
}

</style>






















